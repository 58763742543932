import React from "react";

import BaseLayout from "../../components/layout/BaseLayout";
import AdminRoutes from "../../routes/AdminRoutes";
import HomeIcon from "@mui/icons-material/Home";
import UserIcon from '@mui/icons-material/Person2Outlined';
import MembersIcon from '@mui/icons-material/Groups3';
import RupeeIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import SportsIcon from '@mui/icons-material/Sports';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import CategoryIcon from '@mui/icons-material/Category';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import GradingIcon from '@mui/icons-material/Grading';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
import QuizIcon from '@mui/icons-material/Quiz';
import AssessmentIcon from '@mui/icons-material/Assessment';

import { withTheme } from "@emotion/react";
import { Assessment, Collections, Event, HomeMaxOutlined, OndemandVideo, ViewCarousel } from "@mui/icons-material";

class LayoutContainer extends React.Component {

    render() {

        return (
            <BaseLayout
                params={this.props.params}
                modules={adminModules}
                ChildComponent={AdminRoutes}
            />
        );
    }
}
export default withTheme(LayoutContainer);

const adminModules = [
    {
        id: "dashboard",
        title: "Dashboard",
        link: "/dashboard",
        logo: <HomeIcon />,
    },
    {
        id: "user-manager",
        title: "User Manager",
        link: "/user-manager",
        logo: <UserIcon />,
    },
    {
        id: "members-manager",
        title: "Member Manager",
        link: "/members-manager",
        logo: <MembersIcon />,
    },
    {
        id: "payment-manager",
        title: "Payment Manager",
        link: "/payment-manager",
        logo: <RupeeIcon />,
    },
    {
        id: "common-master",
        title: "Common Masters",
        logo: <ViewModuleIcon />,
        items: [
            {
                logo: <ViewCarousel />,
                id: "banner-master",
                title: "Banner Master",
                role: ["admin"],
                link: "/banner-master",
            },
            {
                id: "location-manager",
                title: "Location",
                link: "/location-manager",
                logo: <AddLocationAltIcon />,
            },
            {
                id: "category-manager",
                title: "Category Manager",
                link: "/category-manager",
                logo: <CategoryIcon />,
            },
            {
                logo: <Collections />,
                id: "photo-gallery-master",
                title: "Photo Gallery Master",
                role: ["admin"],
                link: "/photo-gallery-master",
            },
            {
                logo: <OndemandVideo />,
                id: "video-gallery-master",
                title: "Video Gallery Master",
                role: ["admin"],
                link: "/video-gallery-master",
            },
            {
                logo: <LocalAtmIcon />,
                id: "plans-master",
                title: "Plans Master",
                role: ["admin"],
                link: "/plans-master",
            },
        ]
    },
    {
        logo: <SportsIcon />,
        id: "activity-master",
        title: "Activity Manager",
        link: "/activity-master",
    },
    // {
    //     id: "facility-manager",
    //     title: "Facility",
    //     link: "/facility-manager",
    //     logo: <ManageSearchIcon />,
    // },
    {
        logo: <BatchPredictionIcon />,
        id: "batch-master",
        title: "Batch Manager",
        link: "/batch-master",
    },
    {
        id: "enroll-activity",
        title: "Enroll Activity",
        link: "/enroll-activity",
        logo: <GradingIcon />,
    },
    {
        id: "booking-manager",
        title: "Bookings Manager",
        link: "/bookings-manager",
        logo: <GradingIcon />,
    },
    {
        id: "event-manager",
        title: "Event Manager",
        link: "/event-manager",
        logo: <Event />,
    },
    {
        id: "halls-manager",
        title: "Halls Manager",
        link: "/halls-manager",
        logo: <HomeMaxOutlined />, 
    },
    {
        id: "contact-leads",
        title: "Contact Leads",
        link: "/contact-leads",
        logo: <Assessment />,
        role: ["admin", "sanstha"]
    },
    {
        id: "cms",
        title: "CMS",
        logo: <DynamicFeedIcon />,
        role: ["admin"],
        items: [
            {
                logo: <QuizIcon />,
                id: "faqs",
                title: "FAQs",
                role: ["admin"],
                link: "/faqs",
            },
            {
                logo: <ManageAccountsIcon />,
                id: "teams-manager",
                title: "Teams Manager",
                role: ["admin"],
                link: "/teams-manager",
            },
        ]
    },
    {
        id: "reports",
        title: "Reports",
        logo: <DynamicFeedIcon />,
        role: ["admin"],
        items: [
            {
                logo: <UserIcon />,
                id: "members-reports",
                title: "Members Reports",
                role: ["admin"],
                link: "/members-reports",
            },
            {
                logo: <AssessmentIcon />,
                id: "enrollment-reports",
                title: "Enrollment Reports",
                role: ["admin"],
                link: "/enrollment-reports",
            },
            {
                logo: <AssessmentIcon />,
                id: "batch-wise-reports",
                title: "Batch Wise Reports",
                role: ["admin"],
                link: "/batch-wise-reports",
            },
            {
                logo: <AssessmentIcon />,
                id: "renewal-reports",
                title: "Renewal Reports",
                role: ["admin"],
                link: "/renewal-reports",
            },
            {
                logo: <RupeeIcon />,
                id: "payment-summary-reports",
                title: "Payment Summary",
                role: ["admin"],
                link: "/payment-summary-reports",
            },
        ]
    },
];
